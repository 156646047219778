// src/DesignComponent.js

import React from 'react';
import './GrayCard.css';
import CorrectIcon from '../../../assets/images/CorrectIcon.png';
import CancelIcon from '../../../assets/images/cancel.png';
import { Accordion, Button, Text } from '../../components';
import { useTranslation } from 'react-i18next';

const DesignComponent = () => {
  const { t } = useTranslation();

  const itemsText = [
    { 'Lable1': 'Lorem ipsum ', 'Lable2': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit,Lorem ipsum dolor sit amet, consectetur adipiscing elit' },
    { 'Lable1': 'Lorem ipsum ', 'Lable2': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit,Lorem ipsum dolor sit amet, consectetur adipiscing elit' },
    { 'Lable1': 'Lorem ipsum ', 'Lable2': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit,Lorem ipsum dolor sit amet, consectetur adipiscing elit' },
    { 'Lable1': 'Lorem ipsum ', 'Lable2': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit,Lorem ipsum dolor sit amet, consectetur adipiscing elit' },
    { 'Lable1': 'Lorem ipsum ', 'Lable2': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit,Lorem ipsum dolor sit amet, consectetur adipiscing elit' },
    { 'Lable1': 'Lorem ipsum ', 'Lable2': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit,Lorem ipsum dolor sit amet, consectetur adipiscing elit' },
  ]
  const itemsText1 = t('DesignComponent_Box1_Items', { returnObjects: true });

  return (
    <div className="design-component">
      <div style={{display : "none"}} className="header">
        <Text bold heading >"Lorem ipsum dolor sit amet</Text> <br />
        <Text lineheightsmall medium title1 >consectetur adipiscing elit</Text>

      </div>
      <div className="boxes">
        <div className="box1">
        <h2>{t('DesignComponent_Box1_Title')}</h2>
        {itemsText1.map((item, index) => (
            <div  className="item" key={index}>
              <div> <img src={CorrectIcon} className='icon' /> </div>
              <div>
                <p><Text lineheightsmall bold blackColor body1 >{item.Lable1}</Text></p>
                <p> <Text lineheightsmall medium body2 >{item.Lable2}</Text></p>
              </div>
            </div>
          ))}
        </div>
        {/* <div className="box2">
          <h2 style={{display : "none"}}>Lorem ipsum</h2>
          {itemsText.map((item, index) => (
            <div style={{display : "none"}} className="item" key={index}>
              <div> <img src={CancelIcon} className='icon' /> </div>
              <div>
                <p><Text lineheightsmall bold blackColor body1 >{item.Lable1}</Text></p>
                <p> <Text lineheightsmall medium body2 >{item.Lable2}</Text></p>
              </div>
            </div>
          ))}
        </div> */}
      </div>
    </div>
  );
};

export default DesignComponent;
