import React, { useEffect, useState } from 'react';
import { Text } from '../../components'
import './AlphabetLinks.css';

const AlphabetLinks = ({ onRangeSelect, defaultSelectRange }) => {
  const [activeIndex, setActiveIndex] = useState(defaultSelectRange || 0);
  const links = ['All', 'A - B', 'C - F', 'G - H', 'I - L', 'M - N', 'O - Q', 'R - T', 'U - Z'];

  useEffect(() => {
    setActiveIndex(0);
  }, [defaultSelectRange])

  const handleClick = (index, range) => {
    if (index === activeIndex) {
      setActiveIndex(null);
      onRangeSelect('A - Z');
    }
    else if (range == 'All') {
      setActiveIndex(index);
      onRangeSelect('A - Z');
    }
    else {
      setActiveIndex(index);
      onRangeSelect(range);
    }
  };


  return (
    <div className="alphabet-links">
      {links.map((link, index) => (
        <a
          key={index}
          href="#"
          className={index === activeIndex ? 'active' : ''}
          onClick={() => handleClick(index, link)}
        >
          <Text body2 DarkGrayTextColor={index !== activeIndex} whiteColor={index === activeIndex}>
            {link}
          </Text>
        </a>
      ))}
    </div>
  );
};

export default AlphabetLinks;