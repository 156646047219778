import React, { Component } from 'react';
import { IsEmpty } from '../config/appConstant';
/**
 * HTTP method types
 */
export const HTTPMethod = {
    get: "GET",
    post: "POST",
    put: "PUT",
    delete: "DELETE",
}


/**
 * Default HTTP header content types
 */
export const HTTPHeaderContentType = {
    json: "application/json",
    formURLEncoded: "application/x-www-form-urlencoded",
}

/**
 * A network utility class that performs all the necessary underlying HTTP piping and server authentication work for all HTTP request
 */
export class API extends Component {

    /**
     * Creates a promise that wraps an HTTP request configured with given parameters.
     * 
     * @param {String} method HTTP method
     * @param {String} url The target HTTP request URL
     * @param {props} body The body of the HTTP request
     */

    async request(method, url, body) {
        console.log(url);
        return new Promise((resolve, reject) => {
            let Token = localStorage.getItem('Access_Token')
            if (!IsEmpty(Token)) {
                let headers = {
                    "Accept": HTTPHeaderContentType.json,
                    "Content-Type": HTTPHeaderContentType.json,
                    "Authorization": "Bearer " + Token
                };
                fetch(url, { method: method, headers: headers, body: body })
                    .then((response) => {
                        return response.json()
                    })
                    .then((responseJSON) => {
                        resolve(responseJSON)
                    })
                    .catch((error) => {
                        console.log('e----------', error)
                        reject(error)
                    })

            }
            else {

                let headers = {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded'

                };
                fetch(url, { method: method, headers: headers, body: body })
                    .then((response) => {
                        return response.json()
                    })
                    .then((responseJSON) => {
                        resolve(responseJSON)
                    })
                    .catch((error) => {
                        console.log('ee', error);
                        reject(error)
                    })
            }

        })
    }
    async requestGet(method, url, body) {
        return new Promise((resolve, reject) => {
            if (method.indexOf("token") == -1) {
                let Token = localStorage.getItem('Access_Token')
                let headers = {
                    "Accept": HTTPHeaderContentType.json,
                    "Content-Type": HTTPHeaderContentType.json,
                    "Authorization": "Bearer " + Token
                };
                fetch(url, { method: method, headers: headers, })
                    .then((response) => {
                        return response.json()
                    })
                    .then((responseJSON) => {
                        resolve(responseJSON)
                    })
                    .catch((error) => {
                        reject(error)
                    })
            }
            else {
                let headers = {
                    'Accept': 'application/json',
                    'Content-Type': 'application/x-www-form-urlencoded'

                };
                fetch(url, { method: method, headers: headers, body: body })
                    .then((response) => { console.log(response); return response.json() })
                    .then((responseJSON) => {
                        resolve(responseJSON)
                    })
                    .catch((error) => {
                        console.log(error);
                        reject(error)
                    })
            }

        })
    }

    async requestSiginUp(method, url, body) {
        console.log(url);
        return new Promise((resolve, reject) => {
            let headers = {
                "Accept": HTTPHeaderContentType.json,
                "Content-Type": HTTPHeaderContentType.json,
            };
            fetch(url, { method: method, headers: headers, body: body })
                .then((response) => {
                    return response.json()
                })
                .then((responseJSON) => {
                    resolve(responseJSON)
                })
                .catch((error) => {
                    console.log('e----------', error)
                    reject(error)
                })
        })
    }

    requestTokenHeaders() {
        return {
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }

    /**
     * Builds and returns a prop containing HTTP Header key-value pairs with the appropriate values populated.
     */
    async requestHeaders() {
        const Token = await localStorage.getItem('Access_Token');
        return {
            "Accept": HTTPHeaderContentType.json,
            "Content-Type": HTTPHeaderContentType.json,
            "Authorization": "Bearer " + Token
        }
    }
}

API.shared = new API();
