import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { useState, useEffect } from 'react';
import { StripePaymentRedirectURL, URLs } from '../../API/URLs';
import { formatCurrency, StripePaymentGateway } from '../../config/appConstant';
import './StripPayment.css'; // Import the CSS file

const stripePromise = loadStripe(StripePaymentGateway.PublishKey);

const CheckoutForm = ({ Amount, GUID, clientSecret, onSuccess }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!elements) return;

    setIsProcessing(true);
    try {
      await elements.submit(); // Ensure this is awaited

      const { error } = await stripe.confirmPayment({
        elements,
        clientSecret,
        confirmParams: {
          return_url: StripePaymentRedirectURL.Sucess,
        },
      });

      if (error) {
        setErrorMessage(error.message);
      } else {
        setErrorMessage('');
        onSuccess(); // Call onSuccess if the payment is successful
      }
    } catch (err) {
      setErrorMessage(err.message || 'An unexpected error occurred.');
      console.error('Payment error:', err); // Log errors for debugging
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="payment-form">
      <PaymentElement />
      <button type="submit" disabled={!stripe || !elements || isProcessing} className="payment-button">
        {isProcessing ? 'Processing...' : `${formatCurrency(Amount)} Pay`}
      </button>
      {errorMessage && <div className="error-message">{errorMessage}</div>}
    </form>
  );
};

export default function PaymentComponent(props) {
  const { Amount } = props;
  const [clientSecret, setClientSecret] = useState(null);

  useEffect(() => {
    const fetchClientSecret = async () => {
      const details = {
        amount: (Amount * 100).toFixed(0), // Amount in cents
        currency: 'gbp',
      };

      const formBody = Object.keys(details)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(details[key]))
        .join("&");

      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        "Authorization": "Bearer " + StripePaymentGateway.SecretKey,
      };

      try {
        const response = await fetch(URLs.GetStripeSecretKeys, { method: 'POST', headers, body: formBody });

        if (!response.ok) throw new Error('Failed to fetch the client secret');
        
        const { client_secret: fetchedClientSecret } = await response.json();
        setClientSecret(fetchedClientSecret);
      } catch (error) {
        console.error('Error fetching client secret:', error);
      }
    };

    fetchClientSecret();
  }, [Amount]);

  const options = {
    clientSecret,
    appearance: {
      theme: 'stripe', // Customize the appearance here
    },
  };

  const handlePaymentSuccess = () => {
    // Navigate to the success screen or trigger any other success action
    window.location.href = StripePaymentRedirectURL.Sucess;
  };

  return (
    clientSecret ? (
      <Elements stripe={stripePromise} options={options}>
        <div className="payment-card">
          <h2 className="payment-title">Complete Your Payment</h2>
          <CheckoutForm {...props} clientSecret={clientSecret} onSuccess={handlePaymentSuccess} />
        </div>
      </Elements>
    ) : (
      <div>Loading...</div> // Show a loading state until the client secret is fetched
    )
  );
}
