import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { SearchBar, TagButton, Text } from "..";
import './SearchDestination.css';
import { Images } from '../../config';

const SearchDestinationLabel = [
  {
    'heading': 'Welcome_heading',
    'Subheading': 'Welcome_subheading',
    'placeholder': 'SearchDestination_placeholder'
  },
]

const SearchDestination = (props) => {
  const { handlesearch } = props

  const { t } = useTranslation();
  const dir = useSelector((state) => state.dir);
  const [searchValue, setSearchValue] = useState('');

  const handleChange = (event) => {
    setSearchValue(event.target.value);
    handlesearch(event.target.value)
  };
  return (

    <>
      <div class="search-destination-classmain">
        <div class="search-destination-classleft">
          <div className='search-destination-classleft-paadingLeft'>
            <div className='homescreen-space-bwt'><Text lineheightsmall bold mainColor header >{t(SearchDestinationLabel[0].heading)}</Text></div>
            <div className='homescreen-space-bwt'><Text lineheightsmall medium PurpleColor heading >{t(SearchDestinationLabel[0].Subheading)}</Text> </div>
            <div className='homescreen-searchbar homescreen-space-bwt'>
              <SearchBar
                placeholder={t(SearchDestinationLabel[0].placeholder)}
                maxLength={100}
                value={searchValue}
                onChange={handleChange}
                required />
            </div>
            <div className='homescreen-tagbutton homescreen-space-bwt'>
              <TagButton text="Easy to Use" />
            </div>
          </div>
        </div>
        <div class="search-destination-classRight">
          <div className='searchdestination-background-image' style={{ transform: dir != 'ltr' ? 'scaleX(-1)' : 'scaleX(1)', }}>
            <div className={dir != 'ltr' ? "profile-image-rtl" : "profile-image-ltr"} >
              <div className="search-destination-placeholder">
                <img src={Images.mainlogo} alt="e-SIM LIFE Logo" className="searchdestination-logo-image" />
              </div>
            </div>
          </div>
        </div>
      </div >


    </>
  );
};

export default SearchDestination;