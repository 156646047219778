import { Images } from './Images'
import { useSelector } from "react-redux";
import { forceDark, themeStorage } from '../utils/selector'
import { IsEmpty, getItemStore, storageKeys } from './appConstant';
let TenantConfiguration = !IsEmpty(getItemStore(storageKeys.TenantConfiguration)) ? JSON.parse(getItemStore(storageKeys.TenantConfiguration)) : ''

const eSImLifeOrangeFontFamily = {
  FontFamily_1: 'Nunito, sans-serif',
  FontFamily_2: 'Barlow, sans-serif',
};

export const ThemeSupport = [
  {
    theme: "orange",
    light: {
      dark: false,
      colors: {
        mainColor: '#E15119',
        mainBgColor: '#FFFFFF',
        whiteColor: "#FFFFFF",
        lightGray: "#ECECEC80",
        darkGray: "#CCCCCC",
        blackColor: "#000000",
        lightPurple: "#363848",
        PurpleColor: "#1F2131",
        text: '#000000',
        card: "#FFFFFF",
        redIconColor: '#a94442',
        lightOrange: '#ebebeb',
        lightenOrange: '#F7D3C5',
        lightBlueColor: '#e9e9e9',
        lightblueBgColor: '#f5f5f5',
        DarkGrayTextColor: "#979797",
        lightblueTextColor: "#5479FC",
        darkblueButtonColor: "#011C2A",
        greenColor: '#84ba3f',
        NaviBlueColor: '#0000ff',
        Custom_Family: eSImLifeOrangeFontFamily,
      },
    },
    dark: {
      dark: true,
      colors: {
        mainColor: '#E15119',
        mainBgColor: '#1F2131',
        text: '#FFFFFF',
        card: "#000000",
        lightGray: "#2E2E2E",
        darkGray: "#4A4A4A",
        whiteColor: "#FFFFFF",
        blackColor: "#000000",
        lightPurple: "#1F2131",
        PurpleColor: "#ffff",
        redIconColor: '#a94442',
        lightBlueColor: '#3A3A3A',
        lightenOrange: '#95547D',
        lightOrange: '#333333',
        lightblueBgColor: '#2A2A2A',
        DarkGrayTextColor: "#CCCCCC",
        greenColor: '#84ba3f',
        NaviBlueColor: '#0000ff',

        Custom_Family: eSImLifeOrangeFontFamily,
      },
    },
  },
  {
    theme: "blue",
    light: {
      dark: false,
      colors: {
        mainColor: '#293D64', // Adjusted to a blue color
        mainBgColor: '#FFFFFF',
        whiteColor: "#FFFFFF",
        lightGray: "#ECECEC80",
        darkGray: "#CCCCCC",
        blackColor: "#000000",
        lightPurple: "#363848",
        PurpleColor: "#1F2131",
        text: '#000000',
        card: "#FFFFFF",
        redIconColor: '#a94442',
        lightOrange: '#ebebeb',
        lightenOrange: '#87CEFA', // Adjusted to a light sky blue
        lightBlueColor: '#e9e9e9',
        lightblueBgColor: '#f5f5f5',
        DarkGrayTextColor: "#979797",
        greenColor: '#84ba3f',
        NaviBlueColor: '#0000ff',

        Custom_Family: eSImLifeOrangeFontFamily,
      },
    },
    dark: {
      dark: true,
      colors: {
        mainColor: '#293D64', // Adjusted to a blue color
        mainBgColor: '#1F2131',
        text: '#FFFFFF',
        card: "#000000",
        lightGray: "#2E2E2E",
        darkGray: "#4A4A4A",
        whiteColor: "#FFFFFF",
        blackColor: "#000000",
        lightPurple: "#1F2131",
        PurpleColor: "#FFFFFF", // Kept white for better contrast
        redIconColor: '#a94442',
        lightBlueColor: '#3A3A3A',
        lightenOrange: '#2E2E2E', // Darkened version of #87CEFA
        lightOrange: '#333333',
        lightblueBgColor: '#2A2A2A',
        DarkGrayTextColor: "#CCCCCC",
        greenColor: '#84ba3f',
        NaviBlueColor: '#0000ff',

        Custom_Family: eSImLifeOrangeFontFamily,
      },
    },
  }


]
const eSImLifeOrangeTheme = {
  card: "#FFFFFF",

  mainColor: '#E15119',
  mainBgColor: '#FFFFFF',
  whiteColor: "#FFFFFF",
  lightGray: "#ECECEC80",
  darkGray: "#CCCCCC",
  blackColor: "#000000",
  lightPurple: "#363848",
  PurpleColor: "#1F2131",
  redIconColor: '#a94442',
  lightBlueColor: '#e9e9e9',
  lightblueBgColor: '#f5f5f5',
  DarkGrayTextColor: "#979797",
  NaviBlueColor: '#0000ff',
  Custom_Family: eSImLifeOrangeFontFamily,

}



const eSImLifeBlueTheme = {
  mainColor: '#000011',
  mainBgColor: '#FFFFFF',
  whiteColor: "#FFFFFF",
  blackColor: "#000000",
  redIconColor: '#a94442',
  Custom_Family: eSImLifeOrangeFontFamily,
}


export const graphColors = ["#02ABAE", "#1588ba", "#486e6e", "#0000ff", "#0a5c7f", "#008080", "#ec9455", "#c377e4", "#6EC496", "#1073C6", "#5a6e7f", "#646464", "#669999"];
export const graphColorsRGB = ["rgb(2, 171, 174,0.5)", "rgb(21, 136, 186,0.5)", "rgb(72, 110, 110,0.5)", "rgb(0, 0, 255,0.5)", "rgb(10, 92, 127,0.5)", "rgb(0, 128, 128,0.5)", "rgb(236, 148, 85,0.5)", "rgb(195, 119, 228,0.5)", "rgb(110, 196, 150, 0.5)", "rgb(16, 115, 198, 0.5)", "rgb(90, 110, 127, 0.5)", "rgb(100, 100, 100, 0.5)", "rgb(102, 153, 153,0.5)"];


export const useTheme = () => {
  const forceDark1 = useSelector(forceDark);
  const themeStorage1 = useSelector(themeStorage);
  const listTheme = ThemeSupport.filter((item) => item.theme == themeStorage1);
  const theme = listTheme.length > 0 ? listTheme[0] : eSImLifeOrangeTheme;
  if (forceDark1) {
    return { theme: theme.dark, colors: theme.dark.colors };
  }
  if (forceDark1 == false) {
    return { theme: theme.light, colors: theme.light.colors };
  }

};


const GetTheme = () => {
  switch (TenantConfiguration[0]?.t) {
    case 'Bausch Reach':
      return eSImLifeOrangeTheme
    case 'USOSM':
      return eSImLifeBlueTheme
    default:
      return eSImLifeOrangeTheme
  }
}
export const BaseColor = GetTheme()
export const FontFamily = GetTheme().Custom_Family

export const BaseImages = {
  mainlogo: `url(${Images.mainlogo})`,
  background: `url(${Images.background})`,
}

