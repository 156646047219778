import React, { useEffect, useRef, useState } from 'react';
import './ProgressBar.css'; // Import the CSS file
import { Text } from '../../components';

export default function ProgressBar({ ProgressValue = 50 }) {
  const [count, setCount] = useState(0);
  const intervalRef = useRef(null);

  useEffect(() => {
    // Create an interval to increment count
    intervalRef.current = setInterval(() => {
      setCount((prevCount) => {
        const newCount = prevCount + 1;
        if (newCount >= ProgressValue) {
          clearInterval(intervalRef.current);
          return ProgressValue;
        }
        return newCount;
      });
    }, 100); // Adjust the time interval as needed

    return () => clearInterval(intervalRef.current);
  }, [ProgressValue]);

  return (
    <div className="progress-bar">
      <div
        className="progress-fill"
        style={{
          width: `${Math.round(count)}%`, 
        }}
      />
      <div className="txt-action-button">
      <Text>{`${Math.round(count)}%`}</Text>
      </div>
    </div>
  );
}
