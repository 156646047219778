import React, { useState } from 'react';
import './DestinationTab.css';
import { useTranslation } from 'react-i18next';

const DestinationTab = (props) => {
  const { handleTab, defaultselectedTab, tabs } = props;
  const { t } = useTranslation();

  const [selectedTab, setSelectedTab] = useState(defaultselectedTab || 0);

  const handleTabs = (val) => {
    setSelectedTab(val);
    handleTab(val);
  };

  return (
    <div className="tabs">
      {tabs.map((tabLabel, index) => (
        <div
          key={index}
          className={`tab ${selectedTab === index ? 'selected' : ''}`}
          onClick={() => handleTabs(index)} >
          {t(tabLabel)}
        </div>
      ))}
    </div>
  );
};

export default DestinationTab;
