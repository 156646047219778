import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './Pagination.css'
import { Text } from '../../components';
const Pagination = ({ totalPages, currentPage, onPageChange }) => {
  const [page, setPage] = useState(currentPage);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage);
      onPageChange(newPage);
    }
  };

  const getPageNumbers = () => {
    const maxPagesToShow = 3;
    const range = [];
    const halfRange = Math.floor(maxPagesToShow / 2);

    // Define start and end points for pagination buttons
    let start = Math.max(1, page - halfRange);
    let end = Math.min(totalPages, page + halfRange);

    // Adjust range if it's too short
    if (end - start + 1 < maxPagesToShow) {
      if (start === 1) {
        end = Math.min(totalPages, start + maxPagesToShow - 1);
      } else if (end === totalPages) {
        start = Math.max(1, end - maxPagesToShow + 1);
      }
    }

    // Add first page and ellipses if needed
    if (start > 1) {
      range.push(1);
      if (start > 2) range.push('...');
    }

    // Add the range of pages
    for (let i = start; i <= end; i++) {
      range.push(i);
    }

    // Add ellipses and last page if needed
    if (end < totalPages) {
      if (end < totalPages - 1) range.push('...');
      range.push(totalPages);
    }

    return range;
  };

  return (
    <div className="pagination">
      <button
        onClick={() => handlePageChange(page - 1)}
        disabled={page === 1}
      >
        <Text>
          &lt; Previous
        </Text>
      </button>
      {getPageNumbers().map((num, index) =>
        num === '...' ? (
          <span key={index} className="pagination-ellipsis">...</span>
        ) : (
          <button
            key={index}
            onClick={() => handlePageChange(num)}
            className={num === page ? 'active' : ''}
          >
            <Text>
              {num}
            </Text>
          </button>
        )
      )}
      <button
        onClick={() => handlePageChange(page + 1)}
        disabled={page === totalPages} >
        <Text>Next &gt;</Text>
      </button>
    </div>
  );
};

Pagination.propTypes = {
  totalPages: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default Pagination;
