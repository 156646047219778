import React from 'react';
import Card from '@material-ui/core/Card';
import PropTypes from "prop-types";
import "./card.css";
import { BaseColor } from '../../config';

const index = (props) => {
  
  const { className, style, onClick, CardColor, elevation, variant, children, } = props;
  return (
    <Card
      style={{ ...style, backgroundColor: CardColor }}
      className={`cardMain' ${className}`}
      onClick={onClick}
      elevation={elevation}
      variant={variant} >
      {children}
    </Card>
  );
}
index.propTypes = {
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  onClick: PropTypes.func,
};

index.defaultProps = {
  style: {},
  variant: 'elevation',//outlined
  elevation: 1,
  CardColor: BaseColor.whiteColor,
  onClick: () => { },
};

export default index;
