// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './en.json';
import translationFR from './fr.json';
import translationGU from './gu.json';
import translationHI from './hi.json';
import translationAR from './ar.json';

const resources = {
  en: {
    translation: translationEN,
  },
  fr: {
    translation: translationFR,
  },
  hi: {
    translation: translationHI,
  },
  gu: {
    translation: translationGU,
  },
  ar: {
    translation: translationAR,
  },
};

i18n
  .use(initReactI18next) // Passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en', // Default language
    keySeparator: false, // We do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // React already does escaping
    },
  });

export default i18n;
