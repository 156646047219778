import React, { useEffect, useState } from 'react';
import './EditProfile.css';
import { Button, CommonLoader, Text, TextField } from '../../components';
import { useTranslation } from 'react-i18next';
import * as APIController from "../../API/APIController";
import { toast } from 'react-toastify';
import { IsEmpty } from '../../config/appConstant';
import MobileInput from '../../components/MobileInput/MobileInput';
import { CircularProgress } from '@material-ui/core';

function EditProfile({ onClose }) {
    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [customerCode, setCustomerCode] = useState('');
    const [mobileno, setMobileno] = useState('');
    const [CountryCodeContactNumber, setCountryCodeContactNumber] = useState('');
    const [touched, setTouched] = useState(false);
    const [loading, setLoading] = useState(false);
    const [editLoading, seteditLoading] = useState(false);
    const [successResponse, setSuccessResponse] = useState('');

    const validate = () => {
        if (!name || !CountryCodeContactNumber || !mobileno) {
            setTouched(true);
            toast.error('Please fill out all required fields!');
            return false;
        }
        return true;
    };

    const handleEditProfile = () => {
        if (validate()) {
            wsUpdateCustomerProfile(name, mobileno, CountryCodeContactNumber);
        }
    };

    useEffect(() => {
        wsGetCustomerProfile();
    }, []);

    const wsGetCustomerProfile = () => {
        setLoading(true);
        APIController.GetCustomerProfile()
            .then((response) => {
                setLoading(false);
                if (IsEmpty(response.error)) {
                    if (response.object.Status === 1) {
                        setName(response.object.CustomerName);
                        setCustomerCode(response.object.CustomerCode);
                        setMobileno(response.object.MobileNumber);
                        setEmail(response.object.EmailAddress);
                        setCountryCodeContactNumber(response.object.ContactNumberCountryCode);
                    }
                }
            })
            .catch((err) => {
                setLoading(false);
                console.log('Error fetching profile:', err);
            });
    };

    const wsUpdateCustomerProfile = (CustomerName, MobileNumber, ContactNumberCountryCode) => {
        seteditLoading(true);
        APIController.UpdateCustomerProfile(CustomerName, MobileNumber, ContactNumberCountryCode)
            .then((response) => {
                seteditLoading(false);
                if (IsEmpty(response.error)) {
                    if (response.object.Status === 1) {
                        setSuccessResponse(response.object.Message);
                        toast.success(response.object.Message);
                        if (onClose) {
                            onClose();
                        }
                    } else {
                        toast.error(response.object.Message);
                    }
                }
            })
            .catch((err) => {
                setLoading(false);
                console.error('Error:', err);
                toast.error('Failed to update profile. Please try again.');
            });
    };

    return (
        <>
            {loading ? <CircularProgress />
                :
                <div className="edit-profile-container">
                    <div className='editprofile_heading'><Text headline bold>Edit Profile</Text></div>


                    <div className="form-group">
                        <TextField
                            value={name}
                            name={'Name'}
                            onChange={(e) => setName(e.target.value)}
                            label={t('Name')}
                            required
                            error={name === '' && touched}
                        />
                    </div>
                    <div className="form-group">
                        <MobileInput
                            placeholder={t('Mobile No')}
                            value={`${CountryCodeContactNumber}${mobileno}`}
                            onChange={(phone, countryCode) => {
                                setMobileno(phone);
                                setCountryCodeContactNumber(countryCode);
                            }}
                            error={mobileno === '' && touched}
                        />
                    </div>
                    <div className="form-group">
                        <TextField
                            value={email}
                            name={'Email'}
                            label={t('Email')}
                            required
                            error={email === '' && touched}
                            disabled
                        />
                    </div>
                    <div className="form-group">
                        <TextField
                            value={customerCode}
                            name={'CustomerCode'}
                            label={t('Customer Code')}
                            required
                            error={customerCode === '' && touched}
                            disabled
                        />
                    </div>
                    <div className='editprofile-button'>
                        <Button
                            capsule
                            onClick={handleEditProfile}
                            full
                            Large
                            loading={editLoading}
                            round
                            type={2}
                            title={t('Save')}
                        />
                    </div>
                </div>}
        </>
    );
}

export default EditProfile;
