import { CircularProgress } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { URLs } from '../../API/URLs';
import { AppConstant, GetDataFromURL, UUID } from '../../config/appConstant';
import { setBundle } from '../../redux/store';
export default function SuperPayment(props) {
  const { Amount, GUID } = props;
  const S_T = useSelector((state) => state.S_T);
  const dispatch = useDispatch();

  useEffect(() => {
    GetSuperPayment(Amount)
  }, [])

  const GetSuperPayment = (Amount) => {
    let dataDetail = {
      "currency": AppConstant.SuperPaymentCurrency,
      "cashbackOfferId": UUID(),
      "successUrl": URLs.SuperPaymentRedirectURL.Sucess,
      "cancelUrl": URLs.SuperPaymentRedirectURL.Cancel,
      "failureUrl": URLs.SuperPaymentRedirectURL.Failure,
      "minorUnitAmount": parseInt(Amount * 100),
      "externalReference": 'eSIMLife',
      "test": true
    }
    fetch(URLs.SuperPaymentAuth,
      {
        body: JSON.stringify(dataDetail),
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'accept': 'application/json',
          'checkout-api-key': `${URLs.SuperPaymentToken}`,
          "referer": ".elitemobile.com",
          "user-agent": "Mozilla/4.0 (compatible; MSIE 6.0; Windows NT 5.1; SV1)"
        }
      }).then((data) => { return data.json() })
      .then((response) => {
        let GetFromURL = GetDataFromURL(response.redirectUrl)
        if (GetFromURL.TransactionId != '') {
          let Object = {
            ...S_T,
            SPTransactionId: GetFromURL.TransactionId
          }
          dispatch(setBundle(Object));
          window.open(response.redirectUrl, '_self')
        }
      }).catch((e) => {
        console.log('e:', e)
      })
  }

  return (
    <div style={{ height: '50vh', width: '100wh', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
      <CircularProgress />
    </div>
  )
}